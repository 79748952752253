import { remove as removeDiacritics } from 'diacritics'
import { t } from 'i18n'
import each from 'lodash/each'
import map from 'lodash/map'
import get from 'lodash/get'
import find from 'lodash/find'

const SLIP_SHEET = 'slip_sheet'

const PALLET_KIND_TRANSFORM = {
  [SLIP_SHEET]: 'FIN'
}

export function getMetaValue(name) {
  const metaTag = document.querySelector(`meta[name='${name}']`)
  return metaTag ? metaTag.getAttribute('content') : null
}

export function setMetaValue(name, value) {
  return document.querySelector(`meta[name='${name}']`).setAttribute('content', value)
}

document.addEventListener('click', ({ target }) => {
  const toggleableClass = target.dataset.toggleClass
  if (toggleableClass) {
    toggleableClass.split(' ').forEach(klass => {
      target.classList.toggle(klass)
    })
  }
})

export function normalizeString(string) {
  return removeDiacritics(`${string}`.toLowerCase())
}

export function getBottlesPerPallet({ bottlesPerPalletVersions }, palletKind) {
  return get(bottlesPerPalletVersions, get(PALLET_KIND_TRANSFORM, palletKind, palletKind)) || get(bottlesPerPalletVersions, 'default') || 0
}

export function getBottlesPerLayer({ bottlesPerLayerVersions }, palletKind) {
  if (palletKind === SLIP_SHEET) {
    return 0
  } else {
    return get(bottlesPerLayerVersions, get(PALLET_KIND_TRANSFORM, palletKind, palletKind)) || get(bottlesPerLayerVersions, 'default') || 0
  }
}

export function unitCountToBottles(unitCount, unit, product, palletKind) {
  if (!unitCount) {
    return 0
  }

  switch (unit) {
    case 'pallet':
      return unitCount * getBottlesPerPallet(product, palletKind)
    case 'case':
      return unitCount * product.bottlesPerCase
    default:
      return unitCount
  }
}

export function containerCapacityUsed(orderItems, products, transportKind, { palletCapacities, weightCapacities, palletWeight }, palletKind) {
  const palletCapacity = get(palletCapacities, [transportKind, palletKind]) || get(palletCapacities, [transportKind, 'default'])
  const weightCapacity = weightCapacities[transportKind]

  let palletsLoaded = 0
  let weightLoaded = 0

  each(orderItems, ({ bottleCount, productId, unit, unitCount }) => {
    const product = find(products, { id: productId }) || {}

    const bottlesPerPallet = getBottlesPerPallet(product, palletKind)

    if (bottlesPerPallet) {
      palletsLoaded = palletsLoaded + bottleCount / bottlesPerPallet
    }

    if (product.weight) {
      weightLoaded = weightLoaded + bottleCount * product.weight

      if (unit === 'pallet' && palletKind !== SLIP_SHEET) {
        weightLoaded = weightLoaded + unitCount * palletWeight
      }
    }
  })

  const pctContainerUsedByPallets = Math.ceil(palletsLoaded / palletCapacity * 100)
  const pctContainerUsedByWeight = Math.ceil(weightLoaded / weightCapacity * 100)

  const pctContainerUsed = Math.max(pctContainerUsedByPallets, pctContainerUsedByWeight)

  return {capacityUsed: pctContainerUsed, palletsLoaded: palletsLoaded, weightLoaded: weightLoaded}
}

export function availableUnitChoices(product, palletKind) {
  const { availableUnits, bottlesPerCase } = product
  return map(availableUnits, unit => {
    let countForTranslation = 1
    if (unit === 'case') {
      countForTranslation = bottlesPerCase
    } else if (unit === 'pallet') {
      countForTranslation = getBottlesPerPallet(product, palletKind)
    }

    return {
      value: unit,
      label: t(unit, { scope: `catalog.show.unit_choices_${product.productGroupUnitLabel}`, count: countForTranslation })
    }
  })
}

export function pricingAtQuantity(product, bottleCount) {
  const { price: basePrice, currency, discounts, preDiscountPrice } = product

  const matchingDiscount = find(discounts, ({ startQty, endQty }) => startQty <= bottleCount && (endQty >= bottleCount || endQty === 0))

  if (matchingDiscount) {
    const { price, percent } = matchingDiscount
    return { discounted: true, discountPercent: percent, preDiscountPrice, price, currency }
  } else {
    return { discounted: false, discountPercent: undefined, preDiscountPrice, price: basePrice, currency }
  }
}
